import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import getStaticImageURL from '../shared/getStaticImageURL';

const QuickLinks = ({ programLink }) => {
  const { t } = useTranslation();
  const shortcutLinks = [
    {
      title: 'Opportunities',
      link: '/projects',
      langKey: 'opportunities',
      children: [
        {
          title: 'Projects',
          link: '/projects',
          // logo: require('../assets/img/project-qlink-bg.png'),
          // media: require('../assets/img/project-qlink.jpg'),
          logo: 'project-qlink-bg.png',
          media: 'project-qlink.jpg',
          langKey: 'projects',
        },
        {
          title: 'Analytics',
          link: '/statistics',
          // logo: require('../assets/img/analytics-qlink-bg.png'),
          // media: require('../assets/img/analytics-qlink.jpg'),
          logo: 'analytics-qlink-bg.png',
          media: 'analytics-qlink.jpg',
          langKey: 'analytics',
        },
        {
          title: 'Industrial Parks',
          link: '/industrialParks',
          logo: 'sez-qlink-bg.png',
          media: 'sez-qlink.jpg',
          langKey: 'industrial_parks',
        },
      ],
    },
    {
      title: 'Partners',
      link: '/partners',
      langKey: 'partners',
      children: [
        {
          title: 'Investment Promotion Institutions',
          link: '/partners',
          logo: 'project-qlink-bg.png',
          media: 'partner-qlink.jpg',
          langKey: 'ipi',
        },
        {
          title: 'Industrial Parks',
          link: '/industrialParks',
          logo: 'sez-qlink-bg.png',
          media: 'sez-qlink.jpg',
          langKey: 'industrial_parks',
        },
      ],
    },
    {
      title: 'Publications',
      link: '/publications',
      langKey: 'publications',
      children: [
        {
          title: 'Publications',
          link: '/publications',
          logo: 'project-qlink-bg.png',
          media: 'publication-qlink.jpg',
          langKey: 'publications',
        },
      ],
    },
  ];

  const [value, setValue] = useState(0);

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    if (shortcutLinks[newValue].children.length > 0) {
      setValue(newValue);
    } else {
      navigate(programLink + shortcutLinks[newValue].link);
      setValue(newValue);
    }
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 1100,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div>
      <h4>{t('ql_1')}</h4>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered={!mobileScreen ? true : false}
        className="tabs-links"
        variant={mobileScreen ? 'scrollable' : 'standard'}
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {(shortcutLinks || []).map((quickLink, key) => {
          return (
            <Tab
              id={'parent' + quickLink.langKey}
              key={quickLink.langKey + key}
              label={t(quickLink.langKey)}
              className="tabs-nav"
            ></Tab>
          );
        })}
      </Tabs>
      <Slider {...settings}>
        {(shortcutLinks || []).map((quickLink, key) =>
          key === value
            ? (quickLink || []).children.map((child, ikey) => {
                return (
                  <div key={quickLink.title + key}>
                    <Card
                      onClick={() => {
                        navigate(programLink + child.link);
                      }}
                      key={child.title + ikey}
                      className="quicklink-cards"
                    >
                      <CardActionArea>
                        <img
                          // src={child.media}
                          src={getStaticImageURL(child.media)}
                          className="quicklink-media"
                          alt="quick-link media"
                        ></img>
                        <div className="quicklink-details-section">
                          <CardContent className="cardDetails">
                            <img
                              src={getStaticImageURL(child.logo)}
                              // src={child.logo}
                              className="quicklink-logo"
                              alt="quick-link logo"
                            ></img>
                            <div className="title">
                              <h4>{t(child.langKey)}</h4>
                            </div>
                            <div className="description">
                              <p>{`${t('ql_2')} ${t(child.langKey)}`}</p>
                            </div>
                          </CardContent>
                          <CardActions className="quicklink-action">
                            <Button
                              onClick={() => {
                                navigate(programLink + child.link);
                              }}
                              className="quicklink-button"
                            >
                              {t('read_more')}
                            </Button>
                            <IconButton className="quicklink-arrow">
                              <ArrowRightAltIcon />
                            </IconButton>
                          </CardActions>
                        </div>
                      </CardActionArea>
                    </Card>
                  </div>
                );
              })
            : null
        )}
      </Slider>
    </div>
  );
};

export default QuickLinks;

QuickLinks.propTypes = {
  programLink: PropTypes.any,
};
