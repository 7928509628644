import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import '../styles/prism';
import { Link } from 'gatsby';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import Image from '../components/Image';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import RightArrowIcon from '@material-ui/icons/ArrowRightAlt';
import QuickLinks from '../components/QuickLinks';
import getStaticImageURL from '../shared/getStaticImageURL';
import { isArray, isEmpty } from 'lodash';
import Constants from '../../static/Constants';

const Sector = ({ ...props }) => {
  const { pageContext } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;
  const { t } = useTranslation();
  const baseURL = Constants.apiUrl;
  const isPROD = baseURL == 'https://dipsapi.unido.org' ? true : false;

  if (!data) {
    return;
  }

  const allSectors = [
    {
      title: t('sectors_section_1'),
      url: '/C00',
    },
    {
      title: t('sectors_section_2'),
      url: '/F15',
    },
    {
      title: t('sectors_section_3'),
      url: '/C40',
    },
    {
      title: t('sectors_section_4'),
      url: '/A00',
    },
    ...(isPROD
      ? []
      : [
          {
            title: t('sectors_section_5'),
            url: '/U1',
          },
          {
            title: t('sectors_section_6'),
            url: '/U2',
          },
          {
            title: t('sectors_section_7'),
            url: '/U3',
          },
          {
            title: t('sectors_section_8'),
            url: '/U4',
          },
        ]),

    // {
    //   title: 'Fishing',
    //   url: '/B0501',
    // },
    // {
    //   title: 'Mining of coal and lignite; extraction of peat',
    //   url: '/C10',
    // },
  ];

  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const { headerLogo, headerLogoScroll, screenName } = data || {};

  const loading = false;

  const headerLogos = pageContext.logos || [];
  const {
    title,
    description,
    logos,
    image,
    website,
    parallax,
    gallery,
    langKey,
  } = data;

  let sector_description =
    t(description, {
      returnObjects: true,
    }) || [];

  return (
    <Layout
      programLink={programLink}
      imageName={parallax}
      // title={title}
      title={t('sectors_title')}
      headerLinks={headerLinks}
      logos={headerLogos}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[
        { url: '/', key: 'home', name: 'Home' },
        { url: '/sectors', key: 'value_chains', name: 'Value Chains' },
      ]}
      currentPage={t(langKey)}
      screenName={screenName}
    >
      {data ? (
        <div className="sector-details container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <div className="sectors-list">
                <ul>
                  {allSectors.map((sector, key) => (
                    <Link
                      key={sector.title + key}
                      to={programLink + '/sector' + sector.url}
                    >
                      <li className={sector.title === title ? 'active' : ''}>
                        <div>
                          <p>{sector.title}</p>
                        </div>
                        <RightArrowIcon />
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <div className="sector-gallery">
                <Image imageName={gallery || image} />
              </div>
              <div className="sector-summary">
                <div className="sector-title">
                  <h4>{t(langKey)}</h4>
                </div>
                <div className="sector-description">
                  {/* <p>{t(description)} </p> */}
                  {isArray(sector_description) &&
                    !isEmpty(sector_description) &&
                    sector_description.map((ele, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: t(ele) }}
                        ></p>
                      );
                    })}
                </div>
                <div className="sector-logos">
                  <GridContainer spacing={4}>
                    {website ? (
                      <GridItem xs={12} sm={12} md={4}>
                        <div className="website">
                          <p>
                            <a
                              href={website}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {website.substring(
                                website.lastIndexOf('/') + 1,
                                website.length
                              )}
                            </a>
                          </p>
                        </div>
                      </GridItem>
                    ) : null}

                    {(logos || {}).map((logo, key) => (
                      <GridItem
                        key={logo.imageName + key}
                        xs={12}
                        sm={12}
                        md={4}
                      >
                        <div className="logos">
                          <Image imageName={logo.imageName} />
                        </div>
                      </GridItem>
                    ))}
                  </GridContainer>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ textAlign: 'center', padding: '20px' }}
          >
            <p>
              {loading
                ? t('Loading sector details')
                : t('No Information to display')}
            </p>
          </GridItem>
        </GridContainer>
      )}
      <section style={{ marginBottom: '40px' }} className="quik-links">
        <img
          className="quik-links-image"
          src={getStaticImageURL('invention.jpg')}
          alt="quik-links"
        ></img>
        <div className="container">
          <QuickLinks programLink={programLink}></QuickLinks>
        </div>
      </section>
    </Layout>
  );
};

export default Sector;

Sector.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
